'use strict'

# UserAgent
_ua = do ->
  IE = document.uniqueID
  ltIE6 = typeof window.addEventListener is undefined and typeof document.documentElement.style.maxHeight is undefined
  ltIE7 = typeof window.addEventListener is undefined and typeof document.querySelectorAll is undefined
  ltIE8 = typeof window.addEventListener is undefined and typeof document.getElementsByClassName is undefined
  ltIE9 = IE and typeof window.Worker is undefined
  IE6 = IE and ltIE6
  IE7 = IE and ltIE7 and not ltIE6
  IE8 = IE and ltIE8 and not ltIE7 and not ltIE6
  IE9 = IE and ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  IE10 = IE and not ltIE9 and not ltIE8 and not ltIE7 and not ltIE6
  Webkit = not document.uniqueID and not window.opera and not window.sidebar and not window.orientation and window.localStorage
  Safari = Webkit and navigator.vendor.search(/apple/i) isnt -1
  Chrome = Webkit and navigator.vendor.search(/google/i) isnt -1

  return {
    IE:IE,
    ltIE6:ltIE6,
    ltIE7:ltIE7,
    ltIE8:ltIE8,
    ltIE9:ltIE9,
    IE6:IE6,
    IE7:IE7,
    IE8:IE8,
    IE9:IE9,
    IE10:IE10,
    Firefox:window.sidebar,
    Opera:window.opera,
    Webkit:Webkit,
    Safari:Safari,
    Chrome:Chrome,
    Mobile:window.orientation
  }

# URL
url = do ->
  href = location.href.split('/')

  localRegex = /^\d+\.\d+\.\d+\.\d+/
  workRegex = /^.*\/pc\/[^\/]+\/.*$/

  for val , i in href
    if val is '' or i is href.length - 1 and val.indexOf('.')
      href.splice(i,1)

  if localRegex.test(location.hostname) is true or location.hostname.indexOf('localhost') isnt -1
    length = 2

  else if workRegex.test(location.href) is true
    length = 3

    for val , i in href
      if val is 'pc' and href[i-1] is 'work'
        length = 4

  else
    length = 1

  path = ''

  for j in [0..(length)]
    path += href[j]

    if j is 0
      path += '//'

    else
      path += '/'

  return path

#スムーススクロール関数モーション定義
jQuery.extend(
  jQuery.easing, {
    easeInOutCirc:
      (x, t, b, c, d) ->
        if (t/=d/2) < 1
          return -c/2 * (Math.sqrt(1 - t*t) - 1) + b
        c/2 * (Math.sqrt(1 - (t-=2)*t) + 1) + b
  }
)

window.onpageshow = (evt) ->
  if evt.persisted
    location.reload()
  return

event = {
  all: 'mousedown touchstart pointerdown mouseenter mouseleave'
  type: ''
  start: ''
  enter: ''
  leave: ''
  move: ''
  end: ''
}
# イベント判定

event.check = (e) ->
  _default = e.type
  event.start = _default
  event.move = if _default is 'pointerdown' then 'pointermove' else if _default is 'touchstart' then 'touchmove' else 'mousemove'
  event.end = if _default is 'pointerdown' then 'pointerup' else if _default is 'touchstart' then 'touchend' else 'click'
  event.enter = if _default is 'pointerenter' then _default else if _default is 'touchenter' then _default else 'mouseenter'
  event.leave = if _default is 'pointerleave' then _default else if _default is 'touchleave' then _default else 'mouseleave'
  return

event.on = (type, elm, fn, delegate) ->
  $(document).off(event.all, elm).on(event.all, elm, (e) ->
    e.preventDefault()
    event.check(e)
    if fn isnt null
      if e.type is event.enter or e.type is event.leave
        # mouseenter mouseleaveのとき、コールバック関数を即実行
        fn(this, e)
      else
        if type is 'bind'
          # jQuery $(element).on(event, func);
          $(elm).off(event.end).on(event.end, (e) ->
            fn(this, e)
            return
          )
        else if type is 'live'
          # jQuery $(document).on(event, element, func);
          $(document).off(event.end, elm).on(event.end, elm, (e) ->
            fn(this, e)
            return
          )
        else if type is 'delegate'
          # jQuery $(parentsElement).on(event, element, func);
          $(delegate).off(event.end, elm).on(event.end, elm, (e) ->
            fn(this, e)
            return
          )
    else
      # 引数にコールバック関数が定義されていないときの処理
    return
  )
  return


$(()->
  $id = $('body').attr('id')
  $class = $('body').attr('class')

  if $class.indexOf('mail') isnt 0
    firstDownFlg = true
    firstUpFlg = false
    $('.header-contents').headroom({
      tolerance: {
        up:60
        down:50
      }
      offset:0
      classes: {
        initial: 'animated'
        pinned: 'slideDown'
        unpinned: 'slideUp'
      }
      onPin: ()->
        firstUpFlg = true
        if not firstDownFlg
          return $('.header-contents').removeClass('slideUpFirst')
        return
      onUnpin: ()->
      onTop: ()->
        firstDownFlg = true
        $('.header-contents').removeClass('slideDownFirst slideDown pin outreturn up')
      onNotTop: ()->
        if firstDownFlg
          $('.header-contents').addClass('slideUpFirst')
          return firstDownFlg = false
    })
    $(window).scroll(()->
      if firstUpFlg and $(this).scrollTop() <= $('.header-contents').height()
      else if  firstUpFlg
        $('.header-contents').addClass('outreturn')
        return firstUpFlg = false
      if $(this).scrollTop() <= $('.header-contents').height()+40
        $('.header-contents').addClass('pin')
      else $('.header-contents').removeClass('pin')
      if $(this).scrollTop() >= $('.header-contents').height()
        $('.header-contents.slideDown').addClass('up')
    )
  scrollTop = undefined
  $('.nav-open').bind('touchend', ()->
    scrollTop = $(window).scrollTop()
    $('body').addClass('hidden')
    $('#container').addClass('noscroll').css('top', -scrollTop + 'px')
    $('.header-contents').addClass('stop')
    $('.gnavi,.nav-close').fadeIn(300)
    return
  )
  $('.contact-open').bind('touchend', ()->
    scrollTop = $(window).scrollTop()
    $('body').addClass('hidden')
    $('#container').addClass('noscroll').css('top', -scrollTop + 'px')
    $('.header-contents').addClass('stop')
    $('.nav-contact,.nav-close').fadeIn(300)
    return
  )
  $('.nav-close').bind('touchend', ()->
    $('body').removeClass('hidden')
    $('#container').removeClass('noscroll')
    # $('.header-contents').addClass 'slideDown'
    $('.nav-contact,.gnavi,.nav-close').fadeOut(300)
    setTimeout(()->
      $('.header-contents').removeClass('slideUpFirst slideUp stop')
      return
    , 50)
    $(window).scrollTop(scrollTop)
  )

  hrefDisabled = (e)->
    e.preventDefault()
    return
  $('.nav-open,.contact-open').bind('touchstart', ()->
    $('#container a[href],.gnavi a[href],.nav-contact a[href]').bind('click', hrefDisabled)
    return
  )
  $('.nav-open,.contact-open').bind('touchend', ()->
    setTimeout(()->
      $('.gnavi a[href],.nav-contact a[href]').unbind('click', hrefDisabled)
      return
    , 500)
    return
  )
  $('.nav-close').bind('touchend', ()->
    setTimeout(()->
      $('#container a[href],.gnavi a[href],.nav-contact a[href]').unbind('click', hrefDisabled)
      return
    , 1000)
    return
  )

  $('.has-child-title').click(()->
    if $('+.gnavi-child-list', this).css('display') is 'none'
      $(this).addClass('is-open')
      $('+.gnavi-child-list', this).slideDown('normal')
    else
      $(this).removeClass('is-open')
      $('+.gnavi-child-list', this).slideUp('normal')
    return
  )

  $('.nav-contact-tel-title').click(()->
    if $('+.nav-contact-tel-list', this).css('display') is 'none'
      $(this).addClass('is-open')
      $('+.nav-contact-tel-list', this).slideDown('normal')
    else
      $(this).removeClass('is-open')
      $('+.nav-contact-tel-list', this).slideUp('normal')
    return
  )

  topBtn = $('.r-pagetop')
  fixedCv = $('.p-fixed-cv')
  #最初はボタンを隠す
  topBtn.hide()
  fixedCv.hide()
  #スクロールが300に達したらボタンを表示させる
  $(window).scroll(()->
    if $(this).scrollTop() > 300
      topBtn.fadeIn()
      fixedCv.fadeIn()
    else
      topBtn.fadeOut()
      fixedCv.fadeOut()
    return
  )
  topBtn.click(()->
    $('body,html').animate({
      scrollTop: 0
    }, 500)
    false
  )

  
)
